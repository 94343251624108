<template>
  <div>
    <AppHeader title="实验预约-许可列表"/>
    <Panel>
      <div slot="title">我的许可列表</div>
      <div slot="content">
        <div class="panel-desc" v-if="licenses.length>0">
          点击许可进行实验预约
        </div>
        <div class="grid gap10">
          <div class="grid-cell-1-2" v-for="(lic, idx) in licenses" v-if="licenses.length>0">
            <div class="lic" @click="jumpTo(lic)">
              <img :src="lic.equipment.avatar"/>
              <div class="booking-text" v-text="lic.equipment.name"></div>
              <div :class="'booking-status '+lic.statusClass" v-text="lic.statusText"></div>
            </div>
          </div>
          <div class="grid-cell-1" v-if="licenses.length===0">
            您尚未申请任何实验许可
          </div>
        </div>
      </div>
    </Panel>
    <div class="btn green mt10" @click="jumpToApply">申请许可</div>
  </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import AppHeader from "@/components/AppHeader"
import Panel from "@/components/Panel"

export default {
  components: {
    Panel,
    AppHeader
  },
  data() {
    return {
      licenses: []
    }
  },
  methods: {
    jumpTo(lic) {
      if (lic.jumpTo) {
        this.$router.push({
          path: lic.jumpTo,
          query: {
            equipmentId: lic.equipment.id
          }
        })
      }
    },
    jumpToApply() {
      this.$router.push({
        path: '/license',
        query: {
          tabIndex: 1
        }
      })
    }
  },
  mounted() {
    const _that = this
    this.$http.get("/ucenter/equip/userLicences").then(resp => {
      _that.licenses = resp.data.map(function (lic) {
        lic.equipment.avatar = _that.imgUrl + '/' + lic.equipment.avatar
        if (lic.equipment.allowNobooking) {
          lic.statusText = '无预约上机'
          lic.statusClass = 'no-booking'
          lic.jumpTo = ''
        } else if (lic.equipment.queueCfg && lic.equipment.queueCfg.enabled) {
          //排队上机 TODO
          lic.statusText = '排队上机'
          lic.statusClass = 'queue-booking'
          lic.jumpTo = ''
        } else {
          lic.statusText = '预约上机'
          lic.statusClass = 'booking'
          lic.jumpTo = '/booking-cal'
        }
        return lic
      })
    })
  },
}
</script>